import React from "react";

const Footer = () => {
  return (
    <div className="footer-container">
      <p>Copyright 2023 Jakub Babelek</p>
    </div>
  );
};

export default Footer;
